import React from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout/Layout";
import Button from "../components/ui-kits/Button";
import PageWrapper from "../components/layout/PageWrapper";
import PageTitle from "../components/layout/PageTitle";

const NotFoundPage = ({ location }: PageProps) => {
  const { t } = useTranslation();

  return (
    <Layout location={location}>
      <PageWrapper>
        <PageTitle
          heading={t("404.heading")}
          paragraph={t("404.paragraph")}
        />
        <Button to="/home" size="M">
          {t("404.cta")}
        </Button>
      </PageWrapper>
    </Layout>
  );
}

export default NotFoundPage
